import React from 'react';
import Firebase from './Firebase';

const FirebaseContext = React.createContext();

const FirebaseProvider = ({ children, ...initOptions }) => {
    return <FirebaseContext.Provider value={new Firebase(initOptions)}>{children}</FirebaseContext.Provider>;
};

export const useFirebase = () => React.useContext(FirebaseContext);
export default FirebaseProvider;
