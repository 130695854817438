// src/react-auth0-spa.js
import React, { useState, useEffect } from 'react';
import createAuth0Client from '@auth0/auth0-spa-js';
import { useFirebase } from '../../data/Firebase';

const DEFAULT_REDIRECT_CALLBACK = () => window.history.replaceState({}, document.title, window.location.pathname);
const FIREBASE_CREATE_CUSTOM_TOKEN_URL = 'https://us-central1-yumyum-485cc.cloudfunctions.net/auth/create-token';

const Auth0Context = React.createContext();
const Auth0Provider = ({ children, onRedirectCallback = DEFAULT_REDIRECT_CALLBACK, ...initOptions }) => {
    const [isAuthenticated, setIsAuthenticated] = useState();
    const [user, setUser] = useState();
    const [auth0Client, setAuth0] = useState();
    const [loading, setLoading] = useState(true);
    const [popupOpen, setPopupOpen] = useState(false);
    const firebase = useFirebase();

    useEffect(() => {
        const initAuth0 = async () => {
            let hasRedirect = false;
            const auth0FromHook = await createAuth0Client(initOptions);
            setAuth0(auth0FromHook);

            if (window.location.search.includes('code=') && window.location.search.includes('state=')) {
                const { appState } = await auth0FromHook.handleRedirectCallback();
                onRedirectCallback(appState);
                hasRedirect = true;
            }
            const isAuthenticated = await auth0FromHook.isAuthenticated();

            setIsAuthenticated(isAuthenticated);

            if (isAuthenticated) {
                const user = await auth0FromHook.getUser();
                setUser(user);
                if (hasRedirect) {
                    const idToken = await (await auth0FromHook.getIdTokenClaims()).__raw;
                    // console.log(`id token: ${idToken}`);
                    createFirebaseToken(idToken);
                }
            }

            setLoading(false);
        };
        initAuth0();
        // eslint-disable-next-line
    }, []);

    const loginWithPopup = async (params = {}) => {
        setPopupOpen(true);
        try {
            await auth0Client.loginWithPopup(params);
        } catch (error) {
            console.error(error);
        } finally {
            setPopupOpen(false);
        }
        const user = await auth0Client.getUser();
        setUser(user);
        setIsAuthenticated(true);
    };

    const handleRedirectCallback = async () => {
        setLoading(true);
        await auth0Client.handleRedirectCallback();
        const user = await auth0Client.getUser();
        setLoading(false);
        setIsAuthenticated(true);
        setUser(user);
    };

    const createFirebaseToken = async auth0Token => {
        console.log(`createFirebaseToken`);
        const response = await fetch(FIREBASE_CREATE_CUSTOM_TOKEN_URL, {
            headers: {
                Authorization: `Bearer ${auth0Token}`
            }
        });
        const responseData = await response.json();
        console.log(`firebase token: ${responseData.firebaseToken}`);
        firebase.doSignInWithCustomToken(responseData.firebaseToken);
    };

    return (
        <Auth0Context.Provider
            value={{
                isAuthenticated,
                user,
                loading,
                popupOpen,
                loginWithPopup,
                handleRedirectCallback,
                getIdTokenClaims: (...p) => auth0Client.getIdTokenClaims(...p),
                loginWithRedirect: (...p) => auth0Client.loginWithRedirect(...p),
                getTokenSilently: (...p) => auth0Client.getTokenSilently(...p),
                getTokenWithPopup: (...p) => auth0Client.getTokenWithPopup(...p),
                logout: (...p) => {
                    firebase.doSignOut();
                    auth0Client.logout(...p);
                }
            }}>
            {children}
        </Auth0Context.Provider>
    );
};

export const useAuth0 = () => React.useContext(Auth0Context);
export default Auth0Provider;
