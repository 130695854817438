import React from 'react';
import { Container, Form, Col, Button } from 'react-bootstrap';

import './NewRecipe.css';

const NewRecipe = () => {
    return (
        <Container className="NewRecipe">
            <Form>
                <Form.Group>
                    <Form.Control size="lg" placeholder="Recipe Title" />
                </Form.Group>

                <Form.Row>
                    <Form.Label column lg={2}>
                        Preparation Time
                    </Form.Label>
                    <Col>
                        <Form.Control type="number" placeholder="Normal text" />
                    </Col>
                </Form.Row>

                <Form.Row>
                    <Form.Label column lg={2}>
                        Cooking Time
                    </Form.Label>
                    <Col>
                        <Form.Control type="number" placeholder="Normal text" />
                    </Col>
                </Form.Row>

                <Button className="mt-5" variant="primary" type="submit">
                    Save
                </Button>
            </Form>
        </Container>
    );
};

export default NewRecipe;
