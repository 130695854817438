import React, { Fragment } from 'react';
import { useAuth0 } from '../../components/authentication/Auth0';

const Recipes = () => {
    const { loading, user } = useAuth0();

    if (loading || !user) {
        return <div>Loading...</div>;
    }

    return <Fragment>Recipes</Fragment>;
};

export default Recipes;
