// src/App.js

import React from 'react';
import NavBar from '../../components/generic/NavBar/NavBar';

// New - import the React Router components, and the Profile page component
import { Router, Switch } from 'react-router-dom';
import Recipes from '../Recipes';
import NewRecipe from '../NewRecipe';
import Profile from '../Profile';
import PrivateRoute from '../../components/authentication/PrivateRoute';
import history from '../../utils/history';

import './App.css';

function App() {
    return (
        <div className="App">
            {/* Don't forget to include the history module */}
            <Router history={history}>
                <header>
                    <NavBar />
                </header>
                <Switch>
                    <PrivateRoute path="/" exact component={Recipes} />
                    <PrivateRoute path="/new" exact component={NewRecipe} />
                    <PrivateRoute path="/profile" component={Profile} />
                </Switch>
            </Router>
        </div>
    );
}

export default App;
