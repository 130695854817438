import React, { useEffect } from 'react';
import { useAuth0 } from '../../authentication/Auth0';
import { Link } from 'react-router-dom';

import './NavBar.css';

const NavBar = () => {
    const { isAuthenticated, loginWithRedirect, logout } = useAuth0();

    useEffect(() => {
        if (isAuthenticated === false) {
            loginWithRedirect({});
        }
    }, [isAuthenticated, loginWithRedirect]);

    return (
        <div className="NavBar">
            {!isAuthenticated && <button onClick={() => loginWithRedirect({})}>Log in</button>}
            {isAuthenticated && (
                <span>
                    <Link to="/">Recipes</Link>&nbsp;
                    <Link to="/new">New Recipe</Link>&nbsp;
                    <Link to="/profile">Profile</Link>
                </span>
            )}{' '}
            {isAuthenticated && (
                <button
                    onClick={() =>
                        logout({
                            returnTo: window.location.origin
                        })
                    }>
                    Log out
                </button>
            )}
        </div>
    );
};

export default NavBar;
