import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './containers/App';
import * as serviceWorker from './serviceWorker';

import Auth0Provider from './components/authentication/Auth0';
import FirebaseProvider from './components/data/Firebase';
import history from './utils/history';

import 'bootstrap/dist/css/bootstrap.min.css';

require('dotenv').config();

// A function that routes the user to the right place
// after login
const onRedirectCallback = appState => {
    history.push(appState && appState.targetUrl ? appState.targetUrl : window.location.pathname);
};

ReactDOM.render(
    <FirebaseProvider
        apiKey={process.env.REACT_APP_FIREBASE_API_KEY}
        authDomain={process.env.REACT_APP_FIREBASE_AUTH_DOMAIN}
        databaseURL={process.env.REACT_APP_FIREBASE_DATABASE_URL}
        projectId={process.env.REACT_APP_FIREBASE_PROJECT_ID}
        storageBucket={process.env.REACT_APP_FIREBASE_STORAGE_BUCKET}
        messagingSenderId={process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID}>
        <Auth0Provider
            domain={process.env.REACT_APP_AUTH0_DOMAIN}
            client_id={process.env.REACT_APP_AUTH0_CLIENT_ID}
            redirect_uri={window.location.origin}
            audience={process.env.REACT_APP_AUTH0_AUDIENCE}
            onRedirectCallback={onRedirectCallback}
            cacheLocation={'localstorage'}>
            <React.StrictMode>
                <App />
            </React.StrictMode>
        </Auth0Provider>
    </FirebaseProvider>,
    document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
